@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&subset=latin-ext);
body {
   margin: 0;
   padding: 0;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;

   background-color: #222222;
}

.App {
   max-width: 400px;
   margin: 0 auto;

   background-color: #444444;
   color: #cccccc;
   box-shadow: 0px 0px 3px 0px rgba(209, 209, 209, 0.4);
}

a {
   color: #fff;
   text-decoration: none;
}
a:hover {
   color: #fff;
   text-decoration: underline;
}

.debugBar {
   background-color: #ffd600;
   color: #000000;
   padding: 6px 0;
   font-size: 10px;
}
.debugBar ul {
   margin: 0;
   padding: 0;
   list-style-type: none;
   list-style-image: none;
}
.debugBar ul li {
   display: inline;
   padding: 0 5px;
   border-right: 1px solid #444444;
}
.debugBar ul li:last-child {
   border-right: none;
}

.icon-spin {
   -webkit-animation-name: animation-spin;
           animation-name: animation-spin;
   -webkit-animation-duration: 4500ms;
           animation-duration: 4500ms;
   -webkit-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
           animation-timing-function: linear;
}
@-webkit-keyframes animation-spin {
   from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
   }
   to {
      -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
   }
}
@keyframes animation-spin {
   from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
   }
   to {
      -webkit-transform: rotate(-360deg);
              transform: rotate(-360deg);
   }
}

